import React, {useEffect, useState} from "react"
import _ from "lodash"
import {AppSync} from "../../libraries/appsync"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageTitle from "../../components/PageTitle"
import PageNavigation from "../../components/PageNavigation"
import {ButtonTop} from "../../components/Button"
import {Preview, cryptString} from "../../libraries/preview";
import classNames from "classnames"

export default ({location}) => {
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState([])

  useEffect(() => {
    setLoading(true)

    Preview(cryptString(location)).then(customDateTime => {

      AppSync.post("/", {
        query: `
          query listNews {
            listNews {
              site_c
              news {
                site_c
                srvc_tc
                news_id
                title
                article
                stt_ts
                end_ts
                tags
                thumbnail_url
                dsptgt_tc
                tgtmember_tc
              }
            }
          }
        `
      }).then(res => {
        const news = _.get(res.data, "data.listNews.news", [])
        let tags = []
        for (const newsIndex in news) {
          const newsObj = news[newsIndex]
          if (newsObj.tags !== undefined && newsObj.tags !== null) {
            const newsObjTags = newsObj.tags.split(',')
            tags = _.union(tags, newsObjTags).sort((x, y) => x.localeCompare(y, 'ja'))
          }
        }
        setTags(tags)
        setLoading(false)
      })
    })
  }, [])

  return (
    <Layout>
      <SEO title="タグ一覧"/>
      <section className="inner">
        <PageTitle>TAGS</PageTitle>
        <div className={classNames({loading: loading}, 'container bg-white inner')}>
          <div className="tag-list">
            {tags.map((value, index) => {
              return <a href={`/news/?tag=${encodeURIComponent(value)}`} key={index}><span>#{value}</span></a>
            })}
          </div>
        </div>
        <PageNavigation>
          <ButtonTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}